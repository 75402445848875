<template>
  <datev/>
</template>

<script>
  import Datev from '@/views/AadenPages/export/childPage/DatevSettingFragment'

  export default {
    name: 'DatevSetting',
    components: { Datev },
  }
</script>

<style scoped>

</style>
